import * as React from 'react'
import { Router } from '@reach/router'
import { Container } from '@chakra-ui/react'
import Layout from '../components/layout'
import ActivateDiscount from '../components/activate-discount'


const Discount = ({ pageContext }: { pageContext: {} }) => {
  return (
    <Layout>
      <Container py={20}>
        <Router>
          <PublicRoute path="/discount">
            <ActivateDiscount path='/:voucher' />
          </PublicRoute>
        </Router>
      </Container>
    </Layout>
  )
}

function PublicRoute(props: { children: React.ReactNode, path: string }) {
  return <div>{props.children}</div>
}

export default Discount
