import React, { useEffect, useContext, useCallback, useState } from "react"
import { navigate } from 'gatsby'
import fetch from 'unfetch'
import { useLoads } from 'react-loads'
import { StoreContext } from '../context/store-context'
import { Text, Button, Box } from '@chakra-ui/react'

const ActivateDiscount = (props: { path: string
    voucher?: string }) => {
    const { checkout } = useContext(StoreContext)
    const [result, setResult] = useState("pending")
    const applyDiscount = useCallback(
        (voucher, checkoutId) =>
        fetch(`/.netlify/functions/discount`, {
            method: 'POST',
            body: JSON.stringify({
                discountCode: voucher,
                checkoutId: checkoutId
            }),
        })
        .then((res) => res.json())
        .then((res) => {
            setResult(res.error)
            if (res.error) {
                
              throw new Error("Discount: " + res.error.message)
            } else {
              //console.log(res)
              setResult("success")
              return null
            }
          }),
        [])

    const { load } = useLoads(
        'applyDiscount',
        applyDiscount as any,
        {
            defer: true,
        }
    )
        
    useEffect(() => {
        if (checkout.id) {
            load(props.voucher, checkout.id)
        }
    }, [checkout.id])
    
    return(
        <Box textAlign="center">
        {result == 'pending' ? <Text>Pending</Text> : result == 'success' ? <Text fontSize="xl" fontWeight="bold">Voucher added to your checkout!</Text> : <Text fontSize="xl" fontWeight="bold">{result}</Text>}
        <Button
            gridColumn="span 1/span 1"
            variant="outline"
            mr={3}
            onClick={() => navigate('/')}
            colorScheme="brand.green"
        >
            Continue Shopping
        </Button>
        </Box>
       
    )
}

export default ActivateDiscount